.custom-frame, .paspartu-frame {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
}

.second-frame, .paspartu-frame {
    width: 100.2%;
    height: 100.2%;
    margin-top: -0.4px;
    margin-left: -0.3px;
}

#image-loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgb(238, 238, 238) url("/uploads/loading-spinner.gif") no-repeat center center;
    z-index: 5;
    background-size: 80px;
}

.icheck-primary>input:first-child:checked+label::before {
    background-color: #2A9CF5;
    border-color: #2A9CF5;
}

.active-image {
    border: 2px solid #2A9CF5;
    border-radius: 50%;
}

.fixed-frame-divider {
    margin: 0;
    border-top: 1px solid #2A9CF5;
}

*:disabled {
    opacity: 0.5;
}

.success-label {
    font-size: 16px;
    font-weight: bold;
    background-color: #5bc74b;
    padding: 4px;
    border-radius: 4px;
    color: white;
    width: 60px;
    text-align: center;
    letter-spacing: 0;
}

.description-avatar-image {
    width: 400px;
    height: 400px;
}

#frames-section, #paspartues-section {
    border-bottom: 1px solid #2a9cf5 !important;
    border-top: 1px solid #2a9cf5 !important;
}
@media only screen and (max-width: 767px) {
    .product-details {
        margin-top: 45px;
    }
}

@media only screen and (min-width: 768px) {
    .accordion {
        margin-top: 40px;
    }
}

